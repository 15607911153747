import React, { createRef, useEffect } from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';

import Img4 from './img/3.png';
import Img5 from './img/5.png';
import lottie2 from './animations/2.json';
import lottie3 from './animations/3.json';
import lottie4 from './animations/4.json';

const images = {
  3: Img4,
  4: Img5,
};

const lotties = {
  0: lottie2,
  1: lottie3,
  2: lottie4,
};

const Container = styled.section`
  max-width: 1400px;
  margin: 0 auto;
  padding: 90px 30px;
  ${({ noPaddingBottom }) => noPaddingBottom && `padding-bottom: 0 !important;`}
  ${({ noPaddingTop }) => noPaddingTop && `padding-top: 0 !important;`}
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 60px 30px !important;
    ${({ noPaddingBottom }) =>
      noPaddingBottom && `padding-bottom: 0 !important;`}
  }

  // @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
  //   padding: 60px 30px;
  // }
  
  &.wide {
    max-width: 1780px;
  }
`;

const Column = styled.div`
  flex: 0 0 47%;
  max-width: 47%;

  &.ColumnText {
    @media screen and (min-width: 1401px) {
      ${({ imgFirst }) =>
        imgFirst ? 'padding-right: 100px;' : 'padding-left: 100px;'}
    }
  }

  &.ColumnImgFirst {
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      margin-bottom: 60px;

      &.noMargin {
        margin-bottom: 0;
      }
    }
  }

  &.ColumnImgLast {
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      margin-top: 60px;

      &.noMargin {
        margin-top: 0;
      }
    }
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;

    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopLrg}) {
      max-width: 100%;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
      max-width: initial;
      margin: 0 -30px 0;
      width: calc(100% + 60px);
      display: block;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const Headline = styled.h2`
  font-size: 58px;
  line-height: 1.1;
  margin-bottom: 60px;
  color: #4f289a;
  font-weight: 100;
  max-width: 530px;

  strong {
    font-weight: 600;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.desktopMed}) {
    font-size: 48px;
    margin-bottom: 48px;
  }

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.desktopMed}) {
    font-size: 40px;
    margin-bottom: 24px;
  }
`;

const Paragraph = styled.p`
  font-size: 17px;
  line-height: 1.9;
  font-family: 'Manrope';
  color: #4f289a;
  font-weight: 400;
  max-width: 530px;

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.desktopMed}) {
    font-size: 15px;
  }
`;

const LottieWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  & svg {
    width: 100%;
    height: auto;
  }
`;

const ContentRows = ({
  id,
  imgFirst,
  data,
  noPaddingBottom,
  noPaddingTop,
  isLottie,
  viewBoxOnly,
  viewBoxSize,
  classes,
}) => {
  const animationContainer = createRef();
  let markup;
  let image = isLottie ? (
    <LottieWrapper ref={animationContainer}></LottieWrapper>
  ) : (
    <img loading="lazy" src={images[data.index]} />
  );

  useEffect(() => {
    if (isLottie) {
      const anim = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: lotties[data.index],
        assetsPath: `./images/${data.index + 2}/`,
        rendererSettings: {
          preserveAspectRatio: 'xMaxYMax slice',
          viewBoxOnly: viewBoxOnly,
          viewBoxSize: viewBoxSize,
        },
      });
      return () => anim.destroy();
    }
  }, []);

  if (imgFirst) {
    markup = (
      <>
        <Column
          className={`ColumnImgFirst ${data.index === 2 ? 'noMargin' : ''}`}
        >
          {image}
        </Column>
        <Column className="ColumnText" imgFirst>
          <Headline dangerouslySetInnerHTML={{ __html: data.heading }} />
          <Paragraph dangerouslySetInnerHTML={{ __html: data.paragraph }} />
        </Column>
      </>
    );
  } else {
    markup = (
      <>
        <Column className="ColumnText">
          <Headline dangerouslySetInnerHTML={{ __html: data.heading }} />
          <Paragraph dangerouslySetInnerHTML={{ __html: data.paragraph }} />
        </Column>
        <Column
          className={`ColumnImgLast ${data.index === 1 ? 'noMargin' : ''}`}
        >
          {image}
        </Column>
      </>
    );
  }

  return (
    <Container
      id={id}
      noPaddingBottom={noPaddingBottom}
      noPaddingTop={noPaddingTop}
      className={classes}
    >
      {markup}
    </Container>
  );
};

export default ContentRows;
