import React from 'react';

import Layout from '../components/layout';
import PageHero from '../components/PageHero/PageHero';
import ContentRows from '../components/ContentRows/ContentRows';
import Footer from '../components/Footer/Footer';

import ContentRowJSON from '../data/content-rows.json';
import SEO from "../components/seo";

const tryUs = () => {
  return (
    <Layout>
      <SEO title="Sprawdź nas" />
      <PageHero pageName="sprawdz-nas">
        <h1>
          Sprawdź <strong>nas</strong>
        </h1>
      </PageHero>
      <ContentRows imgFirst data={ContentRowJSON[3]} classes="wide" />
      <Footer>
        <Footer.Heading>
          Brzmi <strong>dobrze?</strong>
        </Footer.Heading>
        <Footer.Paragraph>
          No pewnie! Nic nie tracisz, a możesz tylko zyskać. Daj nam znać, że
          jesteś – wymyślimy dla Ciebie coś specjalnego!
        </Footer.Paragraph>
      </Footer>
    </Layout>
  );
};

export default tryUs;
