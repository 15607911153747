import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import BgImg from './img/background.png';
import JakToDziala from './img/jak-to-dziala.png';
import JakToDzialaMobile from './img/jak-to-dziala-mobile.png';
import SprawdzNas from './img/sprawdz-nas.png';
import SprawdzNasMobile from './img/sprawdz-nas-mobile.png';
import CoOtrzymasz from './img/co-otrzymasz.png';
import CoOtrzymaszMobile from './img/co-otrzymasz-mobile.png';

const heroImages = {
  'jak-to-dziala': JakToDziala,
  'jak-to-dziala-mobile': JakToDzialaMobile,
  'sprawdz-nas': SprawdzNas,
  'sprawdz-nas-mobile': SprawdzNasMobile,
  'co-otrzymasz': CoOtrzymasz,
  'co-otrzymasz-mobile': CoOtrzymaszMobile,
};

const Background = styled.header`
  background-image: url(${BgImg});
  background-size: cover;
  background-position: center;
  color: #fff;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  max-width: 1690px;
  width: 100%;
  margin: 0 auto;
  padding: 0 65px;

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.desktopLrg}) {
    padding: 30px 30px 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    padding: 40px 30px 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 110px 30px 0;
  }

  &.jak-to-dziala,
  &.sprawdz-nas {
    padding: 80px 65px 0;

    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopLrg}) {
      padding: 80px 30px 0;
    }

    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopSm}) {
      padding: 80px 30px 0;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      padding: 110px 30px 0;
    }

    img.mobile-visible {
      @media screen and (max-width: ${props =>
          props.theme.breakpoints.mobile}) {
        width: 100vw;
        margin: 0 -30px;
      }
    }

    h1 {
      @media screen and (max-width: ${props =>
          props.theme.breakpoints.mobile}) {
        margin-bottom: 0;
      }
    }
  }
`;

const Column = styled.div`
  flex: 0 0 50%;
  max-width: 50%;

  &:first-child {
    display: flex;
    align-items: center;
  }

  &:last-child {
    text-align: right;

    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopLrg}) {
      text-align: center;
    }
  }

  h1 {
    font-size: 100px;
    font-weight: 100;
    margin-top: 10%;

    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopLrg}) {
      font-size: 70px;
    }

    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopMed}) {
      font-size: 45px;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      margin-bottom: 55px;
      margin-top: 0;
      text-align: center;
      flex: 1;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
      margin-bottom: 55px;
      text-align: left;
    }
  }

  strong {
    font-weight: 600;

    @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
      display: block;
    }
  }

  img {
    user-select: none;
    height: auto;
    max-width: 100%;

    &.desktop-visible {
      @media screen and (max-width: ${props =>
          props.theme.breakpoints.mobile}) {
        display: none;
      }
    }

    &.mobile-visible {
      display: block;
      max-width: 500px;
      margin: 0 auto;
      width: 100%;

      @media screen and (min-width: ${props =>
          props.theme.minBreakpoints.mobile}) {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const pageHeader = ({ children, pageName }) => {
  return (
    <Background>
      <Container className={pageName}>
        <Column>{children}</Column>
        <Column>
          <img src={heroImages[pageName]} alt="" className="desktop-visible" />
          <img
            src={heroImages[`${pageName}-mobile`]}
            alt=""
            className="mobile-visible"
          />
        </Column>
      </Container>
    </Background>
  );
};

pageHeader.propTypes = {
  children: PropTypes.object,
  pageName: PropTypes.string,
};

export default pageHeader;
